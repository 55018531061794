// src/components/FacebookButton.js
import React from 'react';
import './FacebookButton.css';

const FacebookButton = () => {
  const facebookLink = "https://www.facebook.com/profile.php?id=61561925690174"; // Reemplaza con tu enlace de Facebook

  return (
    <div className="container">
           
    <a href={facebookLink} target="_blank"  className="facebook-button">
      <img src="/icons8-facebook-94.png" alt="Facebook" className="icons8-facebook-94" />
     
    </a>
    </div>
  );
};

export default FacebookButton;
