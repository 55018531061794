// src/components/FacebookButton.js
import React from 'react';
import './WhatsappButton.css';

const WhatsappButton = () => {
  const whatsappLink = "https://wa.me/573116649959"; // Reemplaza con tu enlace de Facebook

  return (
    <div className="container">
           
    <a href={whatsappLink} target="_blank"  className="whatsapp-button">
      <img src="/3d-fluency-whatsapp-logo.png" alt="Whatsapp" className="d-fluency-whatsapp-logo" />
      Solicitar
    </a>
    </div>
  );
};

export default WhatsappButton;
