import React, { useState } from 'react';
import Home from './components/Home';
import Nosotros from './components/Nosotros';
import Asesorias from './components/Asesorias';
import Cursos from './components/Cursos';
import Servicios from './components/Servicios';
import image from './assets/FLM_2.png';
import 'tailwindcss/tailwind.css';
import './App.css';

function App() {
  const [showReadFile, setShowReadFile] = useState(false);
  const [selectedOption, setSelectedOption] = useState('home');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleButtonClick = () => {
    setShowReadFile(true);
  };

  const handleMenuClick = (option) => {
    setSelectedOption(option);
    setIsMobileMenuOpen(false); // Cierra el menú móvil al seleccionar una opción
  };




  
  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-md">
        <div className="max-w-3xl mt-5 mx-7 px-2 sm:px-4 lg:px-6">
          <div className="flex flex-col lg:flex-row items-center justify-between h-24"> 
            <div className="flex items-center">
              <img src={image} alt="Logo" className="h-24 w-auto mr-3 object-cover"/>
              <h1 className="text-7xl font-extralight text-blue-500">FLM</h1>
          
            {/* Botón para el menú móvil */}
            <div className="ml-7 flex items-center lg:hidden">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="text-gray-500 focus:outline-none"
              >
                <svg className="w-15 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
            </div>

            </div>

            {/* Menú principal */}
            <div className="hidden lg:flex lg:items-center lg:justify-center lg:ml-[5rem]">
              <div className="flex space-x-4">
                <a
                  className={`cursor-pointer px-7 py-2 rounded-md text-3xl ${selectedOption === 'home' ? 'menu-item-selected' : 'text-gray-700 hover:text-blue'}`}
                  onClick={() => handleMenuClick('home')}
                >
                  Inicio
                </a>
                <a
                  className={`cursor-pointer px-7 py-2 rounded-md text-3xl ${selectedOption === 'about' ? 'menu-item-selected' : 'text-gray-700 hover:text-blue'}`}
                  onClick={() => handleMenuClick('about')}
                >
                  Nosotros
                </a>
                <a
                  className={`cursor-pointer px-7 py-2 rounded-md text-3xl ${selectedOption === 'courses' ? 'menu-item-selected' : 'text-gray-700 hover:text-blue'}`}
                  onClick={() => handleMenuClick('courses')}
                >
                  Cursos
                </a>
                <a
                  className={`cursor-pointer px-7 py-2 rounded-md text-3xl ${selectedOption === 'services' ? 'menu-item-selected' : 'text-gray-700 hover:text-blue'}`}
                  onClick={() => handleMenuClick('services')}
                >
                  Servicios
                </a>
                <a
                  className={`cursor-pointer px-7 py-2 rounded-md text-3xl ${selectedOption === 'support' ? 'menu-item-selected' : 'text-gray-700 hover:text-blue'}`}
                  onClick={() => handleMenuClick('support')}
                >
                  Asesorias
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Menú móvil desplegable */}
        <div className={`fixed inset-0 bg-gray-800 bg-opacity-75 z-50 ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
          <div className="flex flex-col items-center justify-center h-full space-y-4">
            <a
              className={`cursor-pointer text-white px-3 py-2 rounded-md text-xl font-medium ${selectedOption === 'home' ? 'bg-blue-400' : 'hover:bg-blue-400'}`}
              onClick={() => handleMenuClick('home')}
            >
              Inicio
            </a>
            <a
              className={`cursor-pointer text-white px-3 py-2 rounded-md text-xl font-medium ${selectedOption === 'about' ? 'bg-blue-400' : 'hover:bg-blue-400'}`}
              onClick={() => handleMenuClick('about')}
            >
              Nosotros
            </a>
            <a
              className={`cursor-pointer text-white px-3 py-2 rounded-md text-xl font-medium ${selectedOption === 'courses' ? 'bg-blue-400' : 'hover:bg-blue-400'}`}
              onClick={() => handleMenuClick('courses')}
            >
              Cursos
            </a>
            <a
              className={`cursor-pointer text-white px-3 py-2 rounded-md text-xl font-medium ${selectedOption === 'services' ? 'bg-blue-400' : 'hover:bg-blue-400'}`}
              onClick={() => handleMenuClick('services')}
            >
              Servicios
            </a>
            <a
              className={`cursor-pointer text-white px-3 py-2 rounded-md text-xl font-medium ${selectedOption === 'support' ? 'bg-blue-400' : 'hover:bg-blue-400'}`}
              onClick={() => handleMenuClick('support')}
            >
              Asesorias
            </a>
          </div>
        </div>
      </nav>

      <div className="container width100 px-5 py-5"> 
        {showReadFile && <Home />}
        {selectedOption === 'home' && <Home />}
        {selectedOption === 'about' && <Nosotros />}
        {selectedOption === 'courses' && <Cursos />}
        {selectedOption === 'services' && <Servicios />}
        {selectedOption === 'support' && <Asesorias />} 
      </div>
    </div>
  );
}

export default App;
