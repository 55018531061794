// src/components/FacebookButton.js
import React from 'react';
import './WhatsappButtonGrupo.css';

const WhatsappButtonGrupo = () => {
  const whatsappgrupoLink = "https://chat.whatsapp.com/I6D5dqUAW8j4wErEw7gMjQ";

  return (
    <div className="container">
           
    <a href={whatsappgrupoLink} target="_blank"  className="WhatsappButtonGrupo-button">
      <img src="/3d-fluency-whatsapp-logo2.png" alt="Whatsapp2" className="fluency-whatsapp-logo2" />
     Unirme al grupo
    </a>
    </div>
  );
};

export default WhatsappButtonGrupo;


