import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';



function Presentacion() {



  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'G-3J6E1C7MGF',  // Reemplaza con tu propio ID de GTM
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

    return (

        <>
          <div className="flex flex-col items-start justify-center text-center bg-gradient-to-r from-sky-500 to-indigo-500">
            <div className="flex flex-col items-start justify-center text-center hover:bg-sky-500 transition-colors duration-700 w-full">
            <div className="flex items-center justify-center mb-6 w-full">
              <h2 className="font-serif text-4xl pt-4 font-bold font-serif text-white" id="headingOne">
                BIENVENIDOS A FLM
              </h2>
            </div>
      
            <p className="font-family text-2xl text-white font-semibold items-start pl-4">
              SOMOS TU ALIADO ESTRATÉGICO CON NUESTRAS
            </p>
            <div className="flex flex-col items-center pl-4">
              <p className="text-center font-face text-6xl text-white font-semibold items-start">Soluciones que</p>
              <p className="text-center font-serif text-6xl text-white font-semibold items-start">Innovan</p>
            </div>
            <p className="font-family text-2xl mt-2 text-white pl-4">en la Transformación Digital de tu empresa.</p>
          </div>



          
            </div>


 
          
        </>
      );
    }
  
  export default Presentacion;
  