import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import image1 from '../assets/carrusel/Desarrollo.png';


import image4 from '../assets/carrusel/Potenciar.jpeg';

const Carrusel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [bgColorIndex, setBgColorIndex] = useState(0);

  const images = [
    { id: 1, src: image1, alt: 'Contacto' },


    { id: 4, src: image4, alt: 'asss' },
  ];

  const bgColors = [
    'from-blue-500 via-gray-900 to-black',
    'from-blue-600 via-gray-800 to-black',
    'from-blue-700 via-gray-700 to-black',
  ];

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 7000); // Cambia de imagen cada 7 segundos
    return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
  }, [currentSlide]);

  useEffect(() => {
    const colorInterval = setInterval(() => {
      setBgColorIndex((prevIndex) => (prevIndex + 1) % bgColors.length);
    }, 3000); // Cambia de color cada 3 segundos
    return () => clearInterval(colorInterval);
  }, []);

  return (
    <div className={`relative mx-auto p-4 mt-4 bg-gradient-to-r ${bgColors[bgColorIndex]} text-white transition-colors duration-700`}>
      <div className="overflow-hidden">
        <img
          src={images[currentSlide].src}
          alt={images[currentSlide].alt}
          className="w-full h-auto mx-auto max-w-full md:max-w-lg lg:max-w-xl xl:max-w-2xl"
        />
      </div>
      <div className="absolute top-0 left-0 right-0 flex justify-between px-0 py-28">
        <button
          onClick={prevSlide}
          className="text-white bg-blue-900 rounded-full p-1 md:p-4 lg:p-5 xl:p-6 text-4xl md:text-5xl lg:text-6xl xl:text-7xl focus:outline-none"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={nextSlide}
          className="text-white bg-blue-900 rounded-full p-1 md:p-4 lg:p-5 xl:p-6 text-4xl md:text-5xl lg:text-6xl xl:text-7xl focus:outline-none"
        >
          <IoIosArrowForward />
        </button>
      </div>
      <div className="text-center mt-4">
        <a href="https://wa.me/573116649959" className="font-face text-white text-lg block" target="_blank" rel="noopener noreferrer">
          WhatsApp: +57 311-664-9959
        </a>
        <a href="mailto:flm7709@gmail.com" className="font-face text-white text-lg block" target="_blank" rel="noopener noreferrer">
          Email: flm7709@gmail.com
        </a>
      </div>
    </div>
  );
};

export default Carrusel;










