import React, { useState } from 'react';
import axios from 'axios';
import './ImagenFondo.css'; // Importar los estilos CSS
import './styles.css'; // Importa el archivo CSS

const App = () => {
  const [data, setData] = useState([]);
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [cel, setCel] = useState('');
  const [email, setEmail] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Mostrar el mensaje de confirmación
    const isConfirmed = window.confirm("Se enviarán los datos al sistema. ¿Deseas continuar?");
    
    if (isConfirmed) {
      axios.post('https://www.flmtecnhology.com/api.php', { nombre, apellido, cel, email, mensaje })
        .then(response => {
          // Si la solicitud es exitosa, actualiza el estado y limpia el formulario
          setData([...data, { nombre, apellido, cel, email, mensaje }]);
          setNombre('');
          setApellido('');
          setCel('');
          setEmail('');
          setMensaje('');

          // Mostrar mensaje de éxito y ocultar mensaje de error si hay
          setFormSubmitted(true);
          setFormError(false);

          // Ocultar el mensaje de éxito después de 7 segundos
          setTimeout(() => setFormSubmitted(false), 7000);
        })
        .catch(error => {
          // Maneja los errores aquí
          console.error('Error al enviar los datos:', error);
          setFormError(true);

          // Ocultar el mensaje de error después de 7 segundos
          setTimeout(() => setFormError(false), 7000);
        });
    }
  };

  return (
    <div style={{marginTop:'-15px'}}className="background-container">
      <div className="form-container">
        <h1 className="text-center form-heading">Si quieres tener asesoría personalizada, déjanos tus datos y te contactaremos de inmediato.</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            className="form-input"
            placeholder="Nombre"
            required
          />
          <input
            type="text"
            id="apellido"
            name="apellido"
            value={apellido}
            onChange={(e) => setApellido(e.target.value)}
            className="form-input"
            placeholder="Apellido"
            required
          />
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-input"
            placeholder="Email"
            required
          />
          <input
            type="number"
            id="cel"
            name="cel"
            value={cel}
            onChange={(e) => setCel(e.target.value)}
            className="form-input"
            placeholder="Celular"
            required
          />
          <textarea
            id="mensaje"
            name="mensaje"
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
            className="form-textarea"
            placeholder="Ingresa tu mensaje..."
            rows="4"
            required
          ></textarea>
          <button type="submit" className="form-button">Enviar</button>
        </form>
        {formSubmitted && <div className="bg-green-100 text-green-700 p-2 mt-4 rounded">¡Formulario enviado con éxito!</div>}
        {formError && <div className="bg-red-100 text-red-700 p-2 mt-4 rounded">Hubo un error al enviar los datos. Por favor, inténtalo de nuevo.</div>}
      </div>
    </div>
  );
}

export default App;
