
import React from 'react';
import Image1 from '../assets/Cursos.jpeg';
import { FcPhone } from "react-icons/fc";
import WhatsappButtonGrupo from '../components/WhatsappButtonGrupo.js';
import './WhatsappButtonGrupo.css'; // Asegúrate de importar tu archivo CSS
import Curso from'../assets/Curso.jfif';
function Cursos() {
  return (
    <div style={{ backgroundImage: `url(${Image1})`,marginTop:'10px' }} className="min-h-screen bg-cover bg-center flex flex-col items-center justify-center text-white" >
      <div className="text-center mb-8">
        <h2 className="text-2xl font-serif font-bold text-sky-500">¡Descubre el Mundo del Desarrollo Web! </h2>
        <p className="font-family text-black text-2xl w-full md:w-3/4 mx-auto mt-4">
          ¿Te gustaría crear tus propias páginas web desde cero? Únete a nuestro Curso Básico de HTML, CSS y JavaScript y comienza tu viaje en el desarrollo web. Aprende los fundamentos de las tecnologías que impulsan Internet:
        </p>
        <ul className="list-disc text-2xl list-inside mt-4">
          <li className="font-serif text-red-500">HTML: Construye la estructura de tus páginas.</li>
          <li className="font-serif text-blue-500">CSS: Dale estilo y diseño a tus sitios web.</li>
          <li className="font-serif text-yellow-500">JavaScript: Añade interactividad y dinamismo.</li>
        </ul>
      </div>
  
      <div className="flex flex-col items-center mb-8">

        <img src={Curso} className ="Miimagen h-48 w-48 rounded-md"/>
        <div className="flex flex-col items-center mt-4">
          <h3 className="font-family text-lg font-bold text-black">Descargar material:</h3>
          <a href="/Introduccion_Html_Css_Js.pdf" download="Introduccion_Html_Css_Js.pdf" className="btn btn-primary">Download</a>
        </div>
        <div className="flex flex-col items-center mt-4">
          <h3 className=" font-family text-lg font-bold text-black">Descargar proyecto:</h3>
          <a href="/Proyecto_Introduccion.zip" download="/Proyecto_Introduccion.zip" className="btn btn-primary">Descargar</a>
        </div>
      </div>
  
      <div className="flex flex-col items-center mt-8">
        <h3 className="font-face text-lg font-bold text-black">Entra al grupo de WhatsApp:</h3>
        <a>
          <WhatsappButtonGrupo />
        </a>
      </div>
      <div className="flex flex-row justify-center mt-4">
        <p className="font-family text-black  text-lg font-bold">No se requiere experiencia previa.</p>
        <div className="flex items-center">
          <FcPhone />
          <p className=" font-family text-black  text-lg font-bold ">
            +57 311-664-9959
          </p>
        </div>
      </div>
    </div>
  );
}

export default Cursos;
