import React from 'react';
import WhatsappButton from '../components/WhatsappButton';
import CorreoButton from '../components/CorreoButton';


function Servicios() {
  return (
    <div style={{marginTop:'-10px'}} className="container mx-auto flex flex-col items-center justify-center px-4">
      <section className="text-center mb-8 w-full max-w-4xl">
        <h2 className="text-2xl font-serif font-bold text-sky-500">
          ¡Impulsa tu Negocio al Éxito con Nuestros Servicios de Desarrollo Web y Marketing Digital!
        </h2>
        <p className="font-family text-black text-2xl w-full md:w-3/4 mx-auto mt-4">
          ¿Estás buscando llevar tu negocio al siguiente nivel? En FLM, ofrecemos soluciones integrales de desarrollo y marketing digital que te ayudarán a destacar en el mercado y alcanzar tus objetivos.
        </p>
      </section>

      <section className="mb-8 w-full max-w-4xl">
        <h2 className="font-serif text-4xl font-bold text-center text-gray-700 mb-4">Nuestros Servicios</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="rounded-md border shadow-md p-4">
            <h3 className="font-serif text-2xl font-bold text-gray-700">Desarrollo Web y Móvil</h3>
            <p className="text-gray-600">
              Transformamos tus ideas en sitios web y aplicaciones móviles funcionales y atractivas. Utilizamos tecnologías modernas como React, Tailwind CSS y más para crear experiencias de usuario impecables.
            </p>
          </div>

          <div className="rounded-md border shadow-md p-4">
            <h3 className="font-serif text-2xl font-bold text-gray-700">Marketing Digital</h3>
            <p className="text-gray-600">
              Aumenta tu visibilidad en línea con nuestras estrategias de marketing digital personalizadas. Ofrecemos servicios de SEO, publicidad en redes sociales, campañas de Google Ads y email marketing para asegurar que tu mensaje llegue a la audiencia correcta.
            </p>
          </div>

          <div className="rounded-md border shadow-md p-4">
            <h3 className="font-serif text-2xl font-bold text-gray-700">Diseño Gráfico y de Marca</h3>
            <p className="text-gray-600">
              Captura la esencia de tu marca con nuestros servicios de diseño gráfico. Desde logotipos hasta material promocional, creamos diseños que comunican tu identidad de manera efectiva.
            </p>
          </div>

          <div className="rounded-md border shadow-md p-4">
            <h3 className="font-serif text-2xl font-bold text-gray-700">Consultoría y Estrategia Digital</h3>
            <p className="text-gray-600">
              Te ayudamos a definir y ejecutar estrategias digitales que generan resultados. Analizamos tu mercado y competencia para desarrollar planes de acción que maximicen tu retorno de inversión.
            </p>
          </div>
        </div>
      </section>

      <p className="font-serif text-gray-700 text-4xl mb-4">Contáctanos</p>
      
      <div className="flex justify-center gap-4 mb-8">
        <a href="#" className="inline-block"><WhatsappButton/></a>
        <a href="#" className="inline-block"><CorreoButton/></a>
      </div>
    </div>
  );
}

export default Servicios;
