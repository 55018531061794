// src/App.js
import React from 'react';
import './ImagenAgua.css';    // Importar los estilos CSS
import FacebookButton from '../components/FacebookButton.js';
import InstagramButton from '../components/InstagramButton.js';
import imagen1 from '../assets/Agua.jfif';

function Nosotros() {
  return (
    <div style={{ marginTop: '-30px' }} className="container mx-auto flex flex-col items-center justify-center">
      <div className="image-container">
      
        <div className="text-overlay">
          <h2 className="font-serif text-5xl font-bold text-sky-500 text-opacity-80">Misión</h2>
          <p className="font-serif text-black text-1xl w-full md:w-3/4 mx-auto mt-4">
 
            Alcanzar ser el principal servicio tecnológico brindando la solución en el desarrollo de páginas webs, apps y marketing digital, realizando la identificación, análisis y diseño de las ideas de las empresas, negocios y personas que quieran mejorar sus estrategias de ventas. Nuestros valores: responsabilidad, compromiso y amor por resolver todo en el orden especificado.
    
          </p>
          <br />
          <h2 className="font-serif text-5xl font-bold text-sky-500 text-opacity-80">Visión</h2>
          <p className="font-serif text-black text-1xl w-full md:w-3/4 mx-auto mt-4">
            Llegar a ser una entidad donde estén las soluciones tecnológicas para cualquier tipo de empresa, ofreciendo servicios de desarrollo de apps móviles, de escritorio y aplicaciones web. También brindar asistencia en marketing digital para cualquier cliente que busque público y tecnologías que le ayuden a materializar sus ideas.
          </p><br/>
          <div className="buttons flex justify-center gap-4">
            <a href="#" className="inline-block">
              <FacebookButton />
            </a>
            <a href="#" className="inline-block">
              <InstagramButton />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;
