// src/components/FacebookButton.js
import React from 'react';
import './InstagramButton.css';

const InstagramButton = () => {
  const instagramLink = "https://www.instagram.com/empresa_digital_._/"; // Reemplaza con tu enlace de Facebook

  return (
    <div className="grid-containe">
           
    <a href={instagramLink} target="_blank"  className="instagram-button">
      <img src="/icons8-instagram-94.png" alt="Instagram" className="icons8-instagram-94" />
     
    </a>
    </div>
  );
};

export default InstagramButton;
